import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgPipesModule } from '@app/shared/ng-pipes/ng-pipes.module';
import { TranslateModule } from '@ngx-translate/core';

import { BottomSheetComponent } from './bottom-sheet.component';

@NgModule({
  declarations: [BottomSheetComponent],
  imports: [CommonModule, TranslateModule, NgPipesModule],
  exports: [BottomSheetComponent],
  providers: [],
})
export class BottomSheetModule {}
