<ng-template #content>
  <div
    [@flipInY]
    class="bottom-sheet__card"
    [ngClass]="{ 'bottom-sheet__card--open': isOpen }"
  >
    <div class="bottom-sheet__card--top-bar"></div>

    <div class="bottom-sheet__card--body">
      <div *ngIf="title || titleTpl" class="bottom-sheet__card--title">
        <ng-container *ngIf="title; else titleTpl">
          <h3 style="margin-bottom: 0">
            <strong>{{ title | translate }}</strong>
          </h3>
        </ng-container>
      </div>

      <div
        class="bottom-sheet__card--content"
        [ngClass]="cardContentClass"
        [ngStyle]="cardContentStyle"
      >
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</ng-template>
