import { animate, style, transition, trigger } from '@angular/animations';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { SubSink } from 'subsink';

@Component({
  selector: 'by-bottom-sheet',
  templateUrl: './bottom-sheet.component.html',
  styleUrls: ['./bottom-sheet.component.scss'],
  animations: [
    trigger('flipInY', [
      transition(':enter', [
        style({ transform: 'translateY(100%)' }),
        animate('300ms ease-in-out', style({ transform: 'translateY(0)' })),
      ]),
      transition(':leave', [
        style({ transform: 'translateY(0)' }),
        animate('300ms ease-in-out', style({ transform: 'translateY(100%)' })),
      ]),
    ]),
  ],
})
export class BottomSheetComponent implements OnDestroy {
  @ViewChild('content')
  contentTemplate: TemplateRef<void>;

  @Input()
  set open(open: boolean) {
    if (open) {
      this.onOpen();
    } else {
      this.onClose();
    }
  }

  @Input()
  title: string = null;

  @Input()
  titleTpl: TemplateRef<any>;

  @Input()
  cardContentClass: string;

  @Input()
  cardContentStyle = {};

  @Output()
  close = new EventEmitter();

  isOpen = false;

  private overlayRef: OverlayRef;

  private subs = new SubSink();

  constructor(
    private overlay: Overlay,
    private viewContainerRef: ViewContainerRef,
  ) {}

  onOpen() {
    this.isOpen = true;

    this.overlayRef = this.getOverlay();

    this.subs.add(
      this.overlayRef.backdropClick().subscribe(() => this.onClose()),
    );

    this.overlayRef.attach(
      new TemplatePortal(this.contentTemplate, this.viewContainerRef),
    );
  }

  onClose() {
    this.isOpen = false;
    this.overlayRef?.detach();
    this.close.emit();
  }

  ngOnDestroy() {
    this.onClose();
    this.subs.unsubscribe();
  }

  private getOverlay(): OverlayRef {
    return this.overlay.create({
      hasBackdrop: true,
      backdropClass: ['bottom-sheet__mask'],
      scrollStrategy: this.overlay.scrollStrategies.block(),
    });
  }
}
